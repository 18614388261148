import React from 'react';
import Layout from '../components/Layout';
import Mika from '../images/mika.jpg';


export default function site404(){
    
    return(
    
    <Layout title="404">
    <section className="content-element">
    <h1>404</h1>
    <p><b>Nie znaleziono strony.</b></p>
    <p>Znaleziono za to Mikę puszczającą oko.</p>
    </section>
    <section className="content-element">
    <img src={Mika} alt="Kot Mika puszcza oko" className="rounded"></img>
    </section>
    </Layout>
    );
}